.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background: radial-gradient(circle,#d396bd 0%,#b14151 100%)!important;
  color: white;
  text-align: center;
  padding: 2rem;
}

.MainContainer{
  position: relative;
  min-height: 100vh;
  justify-content: center; 
}

.container{
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
 justify-content: center;
 color: black; 
}

.homeMain{
    background-image: url(./Images/img1.jpg);
    padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
 justify-content: center;
 font-size: 14px;
 color: white;
}

.spanError{
  color:red;
}

/* css added on 5 Aug 2020 */
.form-inline {
    padding: 1.5rem 1rem;
  }
.dropdown {
  /* position: relative; */
  display: inline-block;
  /*position: fixed;*/
  will-change: transform; 
  /* top: 0px; 
  left: 0px;  */
  transform: translate3d(-68px, 25px, 0px);
}


.dropdown-content {
  /* display: none; */
  position: fixed;
  background-color: #b14151;
  min-width: 97px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 2px solid #d86d7d;
  color: white;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
} 

.dropdown-content a:visited{
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #a00b21;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  /*border: 2px solid #b14151;*/
  color: white !important;
} 

.roundButton{
  border-radius: 2.813rem!important;
}

/* css for 5 Aug 2020 end here */
.navbar {
  display: flex;
  align-items: center;
  background: radial-gradient(circle,#d396bd 0%,#b14151 100%)!important;
  /* background: #FF9AA2; */
  color: white;
  font-family: sans-serif;
  font-weight: 300;
  line-height: 3;
  padding: 0px 5% !important;
}
.menulink {
  color: #f5f6f7;
  text-decoration: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 20px;
}

a:hover{
  color: #b14151 !important;
  text-decoration: none !important;
  background-color: transparent;
}
.nav-link.active{
  color: #FF9892 !important;
}
.navbar-nav .nav-item {
  margin: 0 1.875rem;
}

.rightfloat{
  float: right;
}

.videohero1 {
	/* height: 700px; */
	background-image: url(./Images/img2.jpg);
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}

p {
  font-family: "Roboto",sans-serif;
  color: black;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
}

.textonimg
{
    color: white;
    font-weight: bolder;
    font-size: 80px;
    font-family: Playfair Display;
    text-align: center;
}

.textonimg1
{
    color: white;
    font-size: 28px;
    font-family: sans-serif;
    text-align: center;
}

.textonimg2
{
    color: #b14151;
    font-weight: bolder;
    font-size: 40px;
    font-family: Playfair Display;
    text-align: center;
    padding: 30px;
}
/* css for 7 Aug 2020 start here */
.receiverDiv { 
  position: relative;
  /* background: radial-gradient(circle,#FF9892 0%,#D7544C 100%); */
  background-image: url(Images/videocall-blur.jpg);
 /* filter: blur(8px);
  -webkit-filter: blur(6px);*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 59%;
  /*border-radius: 2.5rem;*/
  margin: 0 auto 50px;
  height: 500px;
  /*border: 3px solid #b14151;*/
}

.innerDiv{
  width: 100%;
  position: absolute;
  margin: 225px auto;
}

.callerDiv {
  width: 180px;
  height: 180px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 95px;
  margin-right: 360px;
}


.receiver { 
  background: transparent;
  width: 100%;
   height: 100%;
   /* border-radius: 2.5rem; */
}

.caller {
  background: transparent; 
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  border-color: red;
  border:10px 
}
/* css for 7 Aug 2020 end here */
h1
{
  font-style: oblique;
  font-size: 50px;
  font-family: serif;
  color: brown;
}

.headcommingsoon
{
  margin: 50px;
  font-style: oblique;
  font-size: 80px;
  font-family: serif;
  color: brown;
}


.connectbtn {
  /*width: 120px !important;
  height: 50px !important;*/
  color: pink !important;
  background: blueviolet !important;
  /*border-radius: 10px !important;*/
}
.connectbtn1 {
 /* width: 120px !important;
  height: 50px !important;*/
  color: pink !important;
  background: blueviolet !important;
  /*border-radius: 10px !important;*/
}

.connectbtntemp {
  /*width: 120px !important;
  height: 50px !important;*/
  color: pink !important;
  background:  indianred !important;
  /*border-radius: 10px !important;*/
}

.fltright
{
  float: right;
}

.videoimg
{
  max-width: 100% !important;
  background-image: url(./Images/Hands.png);
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}

.btncoonectbefore
{
  /* margin-left: 600px !important; */
  margin-left: 0 !important;
}

/* Banner */

#banner {
  /* background-color: #e5474b; */
  color: #f2a3a5;
  padding: 13em 0 11em 0;
  background-image: url("Images/imgbanner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 15% left;
  text-align: left;
  position: relative;
  z-index: -1;
}

  #banner input, #banner select, #banner textarea {
    color: #ffffff;
  }

  #banner a {
    color: #ffffff;
  }

  #banner strong, #banner b {
    color: #ffffff;
  }

  #banner h1, #banner h2, #banner h3, #banner h4, #banner h5, #banner h6 {
    color: #fff;
  }

  #banner blockquote {
    border-left-color: #fff;
  }

  #banner code {
    background: none;
    border-color: #fff;
  }

  #banner hr {
    border-bottom-color: #fff;
  }

  #banner input[type="submit"],
  #banner input[type="reset"],
  #banner input[type="button"],
  #banner button,
  #banner .button {
    background-color: #5a5a5a;
    color: #ffffff !important;
  }

    #banner input[type="submit"]:hover,
    #banner input[type="reset"]:hover,
    #banner input[type="button"]:hover,
    #banner button:hover,
    #banner .button:hover {
      background-color: #676767;
    }

    #banner input[type="submit"]:active,
    #banner input[type="reset"]:active,
    #banner input[type="button"]:active,
    #banner button:active,
    #banner .button:active {
      background-color: #4d4d4d;
    }

    #banner input[type="submit"].alt,
    #banner input[type="reset"].alt,
    #banner input[type="button"].alt,
    #banner button.alt,
    #banner .button.alt {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px #fff;
      color: #ffffff !important;
    }

      #banner input[type="submit"].alt:hover,
      #banner input[type="reset"].alt:hover,
      #banner input[type="button"].alt:hover,
      #banner button.alt:hover,
      #banner .button.alt:hover {
        background: rgba(255, 255, 255, 0.25);
      }

      #banner input[type="submit"].alt:active,
      #banner input[type="reset"].alt:active,
      #banner input[type="button"].alt:active,
      #banner button.alt:active,
      #banner .button.alt:active {
        background-color: rgba(255, 255, 255, 0.2);
      }

      #banner input[type="submit"].alt.icon:before,
      #banner input[type="reset"].alt.icon:before,
      #banner input[type="button"].alt.icon:before,
      #banner button.alt.icon:before,
      #banner .button.alt.icon:before {
        color: #f8d1d2;
      }

    #banner input[type="submit"].special,
    #banner input[type="reset"].special,
    #banner input[type="button"].special,
    #banner button.special,
    #banner .button.special {
      background-color: #ffffff;
      color: #e5474b !important;
    }

  #banner:after {
    -moz-transition: opacity 4s ease;
    -webkit-transition: opacity 4s ease;
    -ms-transition: opacity 4s ease;
    transition: opacity 4s ease;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    /* background-color: #f4433682; */
    opacity: 0.25;
  }

  #banner .inner {
    max-width: 65em;
    width: calc(100% - 6em);
    margin: 0 auto;
    position: relative;
    z-index: 0;
    line-height: 1.5;
  }

  #banner h1 {
    font-size: 2em;
    margin: 0 0 1em 0;
    padding: 0;
    letter-spacing: 3px;
    font-weight: 700;
  }

    #banner h1 span {
      font-weight: 400;
    }

  body.is-loading #banner:after {
    opacity: 1;
  }

/* Main */

#main {
  padding: 4em 0 2em 0;
}

#one {
  padding: 6em 0 4em 0;
}

#divvideo
  {
    background-color: antiquewhite;
    padding: 3%;
  }

  #divvideoblurimage
  {
    background-image: url(Images/Videocall.jpg);
  }
  .bg-text {
    color: white;
    font-weight: bold;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
    margin-left: 40em;
    margin-top: -17em;
    text-align: center;
  }

  .nav-link {
    padding: 1rem !important;
    height: 50px;
  }
  
  .btn
  {
    border: 2px solid white !important;
    background-color: #b14151!important;
    color: white !important;
    font-size: 1.25rem;
    padding: .5rem 1rem !important;
  }

  .btn:hover {
    background-color: white!important;
    color: #b14151 !important;
    border: 2px solid #b14151 !important;
  } 

  .btnfb
  {
    border: 2px solid white !important;
    background-color: #3b5998 !important;
    color: white !important;
    font-size: 1.25rem;
    padding: .5rem 1rem !important;
  }

  .btnfb:hover {
    background-color: white!important;
    color: #3b5998 !important;
    border: 2px solid #3b5998 !important;
  } 

  .fa-3x {
    font-size: 3em;
    color: white;
}


#main {
  margin-bottom:none ;
    padding: 4em 0 2em 0;
}

  @media screen and (max-width: 1680px) {

    #banner {
      padding: 15em;
    }

  }

  @media screen and (max-width: 1280px) {

    #banner {
      padding: 8em 0 6em 0;
    }

  }

  @media screen and (max-width: 980px) {

    #banner {
      padding: 12em 0 10em 0;
    }

      #banner br {
        display: none;
      }
      #one {
      padding: 4em 0 2em 0;
    }
      .receiverDiv {
       width:80%;
       height: 500px;
       margin-top: 0 auto 50px;
	  }
  }

  @media screen and (max-width: 736px) {

    #banner {
      padding: 4em 0 2em 0;
    }

      #banner h1 {
        font-size: 1.75em;
      }
      #one {
      padding: 2em 0 0.1em 0;
    }

  }

  @media screen and (max-width: 480px) {

    #banner {
      /*padding: 5.5em 0 0 0;
      background-size: contain;*/
    }
    #banner h2 {
	  font-size:36px;
	  }
    .textonimg1 {
     font-size: 14px;
     padding: 0 10px;
     line-height:16px;
	  }
      #banner ul {
        margin-top: 1em;
      }
      #banner .inner {
        max-width: 90%;
        width: 90%;
      }
     .receiverDiv {
	  width:100%;
	  height: 325px;
          margin-top: -45px;
	  }

	  .innerDiv {
    margin: 45% auto;
    }

    /* Responsive layout - makes a one column-layout instead of a two-column layout */
    .flex-item-right, .flex-item-left {
      flex: 100%;
    }
  }
